import { CheckpromissoryApplyFormListData, CheckPromissoryHomePageModel } from '@/src/interfaces/data-contracts';
import { getDeviceType } from '@/src/store/get-device-type';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { generateGUID } from '@/lib/generate-guid';

export const chequeService = createApi({
  reducerPath: 'chequeService',
  baseQuery: fetchBaseQuery({
    baseUrl: '/api',
    prepareHeaders: (headers) => {
      headers.set('device', getDeviceType());
      headers.set('CorrelationId', generateGUID());
      return headers;
    },
  }),
  endpoints: (builder) => ({
    applyForm: builder.mutation<CheckpromissoryApplyFormListData, FormData>({
      query: (body) => ({
        url: '/pages/checkpromissory/applyForm',
        method: 'POST',
        body,
      }),
    }),
    imageProcessing: builder.mutation<any, FormData>({
      query: (body) => ({
        url: '/pages/checkpromissory/imageProcessing',
        method: 'POST',
        body,
        formData: true,
      }),
    }),
    districts: builder.query({
      query: (params) => ({
        url: `/pages/content/${params.cityId}/districts`,
        method: 'GET',
      }),
    }),
    calculate: builder.mutation<CheckPromissoryHomePageModel, { Amount: string; CheckDate: string }>({
      query: (params) => ({
        url: '/pages/checkpromissory/home',
        method: 'GET',
        params,
      }),
    }),
  }),
});

export const { useApplyFormMutation, useCalculateMutation, useLazyDistrictsQuery, useImageProcessingMutation } =
  chequeService;
