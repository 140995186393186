export const getCdnPath = (path?: string | null) => {
  const CDN_ZONE = process.env.CDN_ZONE;
  const BUILD_NUMBER = process.env.BUILD_NUMBER;
  const CDN_URL = process.env.STATIC_FILES_CDN_URL;

  if (CDN_ZONE === 'true') {
    return `${CDN_URL}${path}?v=${BUILD_NUMBER}`;
  }

  return path ?? '';
};
