import { Bottom } from './bottom/bottom';
import { Kvkk } from './kvkk/kvkk';

const FooterSimple = () => {
  return (
    <footer className="flex flex-col">
      <Kvkk className="md:py-8 py-5" />
      <Bottom />
    </footer>
  );
};

export default FooterSimple;
