import classNames from 'classnames';
import { RelLink } from '../../rel-link';
import React from 'react';
import { ResponseMenuItem } from '@/src/interfaces/data-contracts';

type Props = {
  children?: React.ReactNode;
  className?: string;
  footerItems?: ResponseMenuItem[];
};
export const Links: React.FC<Props> = ({ className, footerItems, ...props }: any) => {
  return (
    <div className={classNames('flex flex-1 justify-between', className)} {...props}>
      {footerItems.map((item: ResponseMenuItem, index: number) => (
        <figure key={item?.name! + index}>
          <figcaption className="mb-5 text-[14px] font-bold text-black1000">
            {item.url && item.url !== 'null' ? (
              <RelLink href={item.url} key={item.url + index}>
                {item.name}
              </RelLink>
            ) : (
              <>{item.name}</>
            )}
          </figcaption>
          {item.subMenuItemList && item.subMenuItemList?.length > 0 && (
            <ul>
              {item?.subMenuItemList.map((child: any, index: any) => (
                <li key={child.url + index} className="mb-3 text-[14px] text-black700 last:mb-0">
                  <RelLink href={child.url}>{child.name}</RelLink>
                </li>
              ))}
            </ul>
          )}
        </figure>
      ))}
    </div>
  );
};
