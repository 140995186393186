import { Bottom } from './bottom/bottom';
import { Kvkk } from './kvkk/kvkk';
import { Top } from './top/top';
import React from 'react';
import { ResponseMenuItem } from '@/src/interfaces/data-contracts';

type Props = {
  footerItems: ResponseMenuItem[];
};
export const Footer: React.FC<Props> = ({ footerItems }) => {
  return (
    <footer className="mt-6 bg-black150 py-6 md:mt-8 md:py-[60px]">
      <Top footerItems={footerItems} />
      <Kvkk className="py-6 md:py-8" />
      <Bottom />
    </footer>
  );
};
