'use client';

import { DeviceContext } from '@/src/store/contexts/device-context';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { HeaderDesktop } from './desktop/desktop';
import MenuItemsApi from './header.json';
import { HeaderMobile } from './mobile/mobile';
import { NavMenu } from './types';
import { ResponseMenuItem } from '@/src/interfaces/data-contracts';

const menuItems: NavMenu = JSON.parse(JSON.stringify(MenuItemsApi));
type Props = {
  headerItems: ResponseMenuItem[];
  hamburgerItems: ResponseMenuItem[];
};
export const Header: React.FC<Props> = ({ headerItems, hamburgerItems }) => {
  // headerItems = headerItems.sort((a, b) => a?.displayOrder! - b?.displayOrder!);
  hamburgerItems = hamburgerItems.sort((a, b) => a?.displayOrder! - b?.displayOrder!);
  // const [openMenu, setOpenMenu] = useState(false);
  const headerRef = useRef(null);
  // useEffect(() => {
  //   if (openMenu) {
  //     document.body.classList.add('overflow-hidden');
  //     headerRef.current.className = 'fixed';
  //   } else {
  //     document.body.classList.remove('overflow-hidden');
  //     headerRef.current.className = 'sticky';
  //   }
  // }, [openMenu]);
  const { isMobile } = useContext(DeviceContext);
  return (
    // todo replace div tag with header tag later, currently bugged in nextjs @v13.0.0
    <header ref={headerRef} className="sticky top-0 z-50 h-[48px] w-full border-b border-b-border bg-white md:h-auto">
      {isMobile ? (
        <HeaderMobile headerRef={headerRef} menuItems={hamburgerItems} />
      ) : (
        <HeaderDesktop menuItems={headerItems} />
      )}
    </header>
  );
};
