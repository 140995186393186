import classNames from 'classnames';
import { Logo } from '../../../icons/icons';
import { UserAvatar } from '@/src/features/login/user-avatar';

export const Top = (props: { openMenu: boolean; setOpenMenu: Function }) => {
  const openmenu = props.openMenu;
  const setOpenMenu = props.setOpenMenu;
  return (
    <div
      className={classNames(
        openmenu ? 'openMenu' : '',
        'mobileTop z-50  flex w-full items-center justify-between border-b border-b-border bg-white'
      )}
    >
      <div
        className={classNames(
          openmenu ? 'hmgrOpened' : '',
          'hmbrMenu :focus:outline-none z-10 ml-5 flex h-8 w-8 cursor-pointer flex-col justify-around border-none bg-transparent p-0'
        )}
        onClick={() => setOpenMenu(!openmenu)}
      >
        <div className="relative h-1 w-8 origin-[1px] rotate-0 rounded-lg bg-black1000 transition-all duration-300" />
        <div className="relative h-1 w-8 origin-[1px] translate-x-0 rounded-lg bg-black1000 opacity-100 transition-all duration-300" />
        <div className="relative h-1 w-8 origin-[1px] rotate-0 rounded-lg bg-black1000 transition-all duration-300" />
      </div>
      <a
        href="/"
        onClick={() => (window.location.href = '/')}
        aria-label="Hangikredi Kobi"
        className="bg-transparent px-4 py-2"
        title="Hangikredi Kobi"
      >
        <Logo className="logo" width={113} height={32} />
      </a>
      <div className="mr-5">
        <UserAvatar />
      </div>
    </div>
  );
};
